import './App.scss';
import React from 'react';

class Resolution extends React.Component {

  constructor( props ) {
    super( props );

    this.state = {
      height : window.innerHeight, 
      width : window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatedWindowDimension);
  }

  componentWillUnmount() { 
    window.removeEventListener('resize', this.updatedWindowDimension);
  } 

  updatedWindowDimension = () => { 
    this.setState({
      height : window.innerHeight, 
      width : window.innerWidth
    });
  }

  render( ) {

    let { width, height } = this.state;
    const image = this.props.image(); 
    
    if( !image ) 
      return;

    return (
      <div className="resolutions">
        <div className="resolution image">
          <span className="resolutionWidth">{image.naturalWidth}px</span>
          <span> x </span>
          <span className="resolutionHeight">{image.naturalHeight}px</span>
        </div>
        <div className="resolution window">
          <span className="resolutionWidth">{width}px</span>
          <span> xxx </span>
          <span className="resolutionHeight">{height}px</span>
        </div>
      </div>
    );
  } 
}

class Warning extends React.Component {

  constructor( props ) {
    super( props );

    this.state = {
      width : window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatedWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatedWindowDimension);
  }

  updatedWindowDimension = () => { 
    this.setState({
      width : window.innerWidth
    });
  }

  render( ) {
    
    const image = this.props.image(); 

    if( !image ) 
      return;

    let diff = image.clientWidth - image.naturalWidth;
    console.log( image.clientWidth, image.naturalWidth, diff );

    // let difftext;

    if( diff >= 0 ) 
      return;

    return (
      <div className="warning">        
        <div className="diff">
          <h1>Problem erkannt</h1>
          <div className="message">Bitte vergrößere dein Browserfenster oder wechsle auf ein größeres Display, damit die Vorschau in der Originalauflösung angezeigt werden kann.</div>
          <p><em>Deine Auflösung:</em>&nbsp;<strong>{image.clientWidth}px</strong> &nbsp; <em>Benötigte Auflösung:</em>&nbsp;<strong>{image.naturalWidth}px</strong></p>
        </div>
      </div>
    );
  } 
}



class App extends React.Component {

  constructor( props ) {
    super( props ); 


    let params = new URLSearchParams( window.location.search );
    let source = params.get('img');

    this.state = {
      source, 
      image : false, 
      imageLoaded : false
    }

    this.imageRef = React.createRef();
  }


  componentDidMount = () => {

    let image = new Image(); 
    image.src = this.state.source;    
    image.onload = () => {
      this.setState( {image} );
    };
  }

  imageLoaded = () => {
    this.setState({ imageLoaded : true });
  }

  getImage = () => {
    return this.imageRef.current;
  }

 
  render() {

    if( this.state.image ) {
      return (
        <div className="App">
          <div className="showcase">
            <div className='showcase-inner'>
              <img ref={this.imageRef} src={this.state.image ? this.state.image.src : this.state.source} onLoad={this.imageLoaded} className="showcase-image" alt="Vorschau" title="Vorschau" />
            </div>
          </div>
          <Resolution image={this.getImage} ></Resolution>
          <Warning image={this.getImage} />
        </div>
      );
    }
    else  
      return <div className="loading"></div>;
  } 
}

export default App;
